import React, { useState, useEffect } from "react";

const Countdown = () => {
  const targetDate = localStorage.getItem("targetDate")
    ? new Date(localStorage.getItem("targetDate"))
    : new Date();
  targetDate.setHours(targetDate.getHours() + 24); // Agrega 24 horas a la fecha actual

  const calculateTimeLeft = () => {
    const difference = +targetDate - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    localStorage.setItem("targetDate", targetDate.toISOString()); // Almacena la fecha de destino en LocalStorage

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const { hours, minutes, seconds } = timeLeft;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "2em",
        }}
      >
        <div style={{ fontSize: "1.2rem" }}>{hours}</div>
        <div style={{ textTransform: "uppercase", fontSize: "0.6rem" }}>
          HORAS
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 10px",
          fontSize: "2em",
        }}
      >
        <div style={{ fontSize: "1.2rem" }}>{minutes}</div>
        <div style={{ textTransform: "uppercase", fontSize: "0.6rem" }}>
          MINUTOS
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "2em",
        }}
      >
        <div style={{ fontSize: "1.2rem" }}>{seconds}</div>
        <div style={{ textTransform: "uppercase", fontSize: "0.6rem" }}>
          SEGUNDOS
        </div>
      </div>
    </div>
  );
};

export { Countdown };
